import React, { useEffect, useState } from 'react';
import { Table, Button, Icon, Input, Pagination, Modal, Form, Dropdown } from 'semantic-ui-react';

const XSDGrid = () => {
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [voucherTypes, setVoucherTypes] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [formData, setFormData] = useState({
        XSDTYPE: '',
        XDSDESCRIPTION: '',
        ISSUMMARY: 0,
    });
    const [lookupOpen, setLookupOpen] = useState(false); // Estado para mostrar el dropdown lookup

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const token = localStorage.getItem('token');

                const xsdResponse = await fetch('https://logiconefeuat.azurewebsites.net/in/xsd/api', {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    }
                });
                const voucherResponse = await fetch('https://logiconefeuat.azurewebsites.net/in/vouchertype/api', {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    }
                });

                const xsdResult = await xsdResponse.json();
                const voucherResult = await voucherResponse.json();

                if (!xsdResult.WithError && !voucherResult.WithError) {
                    setData(xsdResult.Data || []);
                    setFilteredData(xsdResult.Data || []);
                    setVoucherTypes(voucherResult.Data || []);
                } else {
                    setError('Error al cargar los XSD o Voucher Types.');
                }
            } catch (err) {
                setError('Error al cargar los datos.');
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const handleSearch = (e) => {
        const term = e.target.value.toLowerCase();
        setSearchTerm(term);
        const filtered = data.filter(item =>
            item.XSDTYPE.toLowerCase().includes(term) || 
            item.XDSDESCRIPTION.toLowerCase().includes(term)
        );
        setFilteredData(filtered);
        setCurrentPage(1);
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(filteredData.length / itemsPerPage);

    const handleClickPage = (e, { activePage }) => {
        setCurrentPage(activePage);
    };

    const handleRowClick = (row) => {
        setSelectedRow(row);
    };

    const handleNew = () => {
        setIsEditing(false);
        setFormData({
            XSDTYPE: '',
            XDSDESCRIPTION: '',
            ISSUMMARY: 0,
        });
        setIsModalOpen(true);
    };

    const handleEdit = () => {
        if (selectedRow) {
            setIsEditing(true);
            setFormData({
                XSDTYPE: selectedRow.XSDTYPE,
                XDSDESCRIPTION: selectedRow.XDSDESCRIPTION,
                ISSUMMARY: selectedRow.ISSUMMARY,
            });
            setIsModalOpen(true);
        }
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
        setLookupOpen(false); // Cerrar el dropdown al cerrar el modal
        setFormData({
            XSDTYPE: '',
            XDSDESCRIPTION: '',
            ISSUMMARY: 0,
        });
    };

    const handleFormChange = (e, { name, value }) => {
        setFormData({ ...formData, [name]: value });
    };

    const handleCheckboxChange = (e, { checked }) => {
        setFormData({ ...formData, ISSUMMARY: checked ? 1 : 0 });
    };

    const handleXSDTypeSelect = (value, description) => {
        setFormData({
            ...formData,
            XSDTYPE: value,
            XDSDESCRIPTION: description
        });
        setLookupOpen(false); // Cerrar el dropdown después de seleccionar
    };

    const handleSubmit = () => {
        if (isEditing) {
            console.log('Editar:', formData);
        } else {
            console.log('Nuevo:', formData);
        }
        setIsModalOpen(false);
    };

    const handleDelete = () => {
        if (selectedRow) {
            console.log('Eliminar:', selectedRow);
            setSelectedRow(null);
        }
    };

    const handleLookupToggle = () => {
        setLookupOpen(!lookupOpen);
    };

    if (loading) return <div>Cargando...</div>;
    if (error) return <div>{error}</div>;

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '15px' }}>
                <Button.Group>
                    <Button icon labelPosition='left' color='grey' onClick={handleNew}>
                        <Icon name='plus' /> Nuevo
                    </Button>
                    <Button icon labelPosition='left' color='grey' onClick={handleEdit} disabled={!selectedRow}>
                        <Icon name='edit' /> Editar
                    </Button>
                    <Button icon labelPosition='left' color='grey' onClick={handleDelete} disabled={!selectedRow}>
                        <Icon name='trash' /> Eliminar
                    </Button>
                </Button.Group>
                <Input
                    icon='search'
                    placeholder='Buscar...'
                    value={searchTerm}
                    onChange={handleSearch}
                    style={{ width: '250px' }}
                />
            </div>

            <Table celled selectable>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Tipo de XSD</Table.HeaderCell>
                        <Table.HeaderCell>Descripción de XSD</Table.HeaderCell>
                        <Table.HeaderCell>¿Es resumen?</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {currentItems.map(item => (
                        <Table.Row
                            key={item.Id}
                            onClick={() => handleRowClick(item)}
                            active={selectedRow && selectedRow.Id === item.Id}
                        >
                            <Table.Cell>{item.XSDTYPE}</Table.Cell>
                            <Table.Cell>{item.XDSDESCRIPTION}</Table.Cell>
                            <Table.Cell>{item.ISSUMMARY ? 'Sí' : 'No'}</Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>

            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                <Pagination
                    activePage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handleClickPage}
                    boundaryRange={1}
                    siblingRange={1}
                    firstItem={{ content: <Icon name='angle double left' />, icon: true }}
                    lastItem={{ content: <Icon name='angle double right' />, icon: true }}
                    prevItem={{ content: <Icon name='angle left' />, icon: true }}
                    nextItem={{ content: <Icon name='angle right' />, icon: true }}
                />
            </div>

            <Modal open={isModalOpen} onClose={handleModalClose}>
                <Modal.Header>{isEditing ? 'Editar XSD' : 'Nuevo XSD'}</Modal.Header>
                <Modal.Content style={{ width: '50%' }}>
                    <Form>
                        <Form.Field>
                            <label>Tipo de XSD</label>
                            <Input
                                placeholder='Tipo de XSD'
                                value={formData.XSDTYPE}
                                onClick={handleLookupToggle} // Mostrar el dropdown al hacer clic
                                readOnly // Hace que el campo de entrada sea de solo lectura
                            />
                            {lookupOpen && (
                                <Dropdown
                                    style={{
                                        position: 'absolute',
                                        zIndex: 1000,
                                        width: '100%',
                                        marginTop: '5px',
                                    }}
                                    open={lookupOpen}
                                    onBlur={() => setLookupOpen(false)} // Cerrar el dropdown al salir del campo
                                    onFocus={() => setLookupOpen(true)} // Abrir el dropdown al entrar en el campo
                                    fluid
                                    multiple
                                    selection
                                    options={voucherTypes.map(v => ({
                                        key: v.NCFVoucherType,
                                        text: `${v.NCFVoucherType} - ${v.NCFVoucherTypeDesc}`,
                                        value: v.NCFVoucherType,
                                        description: v.NCFVoucherTypeDesc,
                                    }))}
                                    onChange={(e, { value }) => {
                                        const selected = voucherTypes.find(v => v.NCFVoucherType === value[0]);
                                        if (selected) {
                                            handleXSDTypeSelect(selected.NCFVoucherType, selected.NCFVoucherTypeDesc);
                                        }
                                    }}
                                />
                            )}
                        </Form.Field>
                        <Form.Field>
                            <label>Descripción de XSD</label>
                            <Input
                                placeholder='Descripción de XSD'
                                name='XDSDESCRIPTION'
                                value={formData.XDSDESCRIPTION}
                                onChange={handleFormChange}
                            />
                        </Form.Field>
                        <Form.Checkbox
                            label='¿Es resumen?'
                            checked={formData.ISSUMMARY === 1}
                            onChange={handleCheckboxChange}
                        />
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={handleModalClose}>Cancelar</Button>
                    <Button positive onClick={handleSubmit}>
                        Guardar
                    </Button>
                </Modal.Actions>
            </Modal>
        </div>
    );
};

export default XSDGrid;
