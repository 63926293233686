import React, { useEffect, useState } from 'react';
import { Table, Button, Icon, Input, Pagination, Modal, Form } from 'semantic-ui-react';

const CertificadosGrid = () => {
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);

    const [formData, setFormData] = useState({
        RNC: '',
        CertificateBase64: null,
        CertificatePass: '',
    });

    // Llamada a la API
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const token = localStorage.getItem('token');
                const response = await fetch('https://logiconefeuat.azurewebsites.net/in/Certificate/api', {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    }
                });
                const result = await response.json();
                if (!result.WithError) {
                    setData(result.Data || []);
                    setFilteredData(result.Data || []);
                } else {
                    setError(result.Message || 'Error al cargar los certificados.');
                }
            } catch (err) {
                setError('Error al cargar los certificados.');
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const handleSearch = (e) => {
        const term = e.target.value.toLowerCase();
        setSearchTerm(term);
        const filtered = data.filter(item => 
            item.IssuedBy.toLowerCase().includes(term) || 
            item.CertificateSerialNumber.toLowerCase().includes(term)
        );
        setFilteredData(filtered);
        setCurrentPage(1);
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(filteredData.length / itemsPerPage);

    const handleClickPage = (e, { activePage }) => {
        setCurrentPage(activePage);
    };

    const handleNew = () => {
        setIsEditMode(false);
        setFormData({
            RNC: '',
            CertificateBase64: null,
            CertificatePass: '',
        });
        setModalOpen(true);
    };

    const handleChange = (e, { name, value }) => {
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64data = reader.result.split(',')[1]; // Obtén solo la parte base64
                setFormData(prev => ({ ...prev, CertificateBase64: base64data }));
            };
            reader.readAsDataURL(file); // Lee el archivo como Data URL
        }
    };

    const handleDelete = async () => {
        if (!selectedRow) return;

        const confirmed = window.confirm("¿Estás seguro de que quieres eliminar este certificado?");
        if (!confirmed) return;

        const token = localStorage.getItem('token');
        try {
            const response = await fetch(`https://logiconefeuat.azurewebsites.net/in/Certificate/api/${selectedRow}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            const result = await response.json();
            if (!result.WithError) {
                setLoading(true);
                const newDataResponse = await fetch('https://logiconefeuat.azurewebsites.net/in/Certificate/api', {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    }
                });
                const newData = await newDataResponse.json();
                setData(newData.Data || []);
                setFilteredData(newData.Data || []);
                setLoading(false);
                setSelectedRow(null);
            } else {
                alert(result.Message || 'Error al eliminar el certificado.');
            }
        } catch (err) {
            alert('Error al eliminar el certificado.');
        }
    };

    const handleSubmit = async () => {
        const { RNC, CertificateBase64, CertificatePass } = formData;
        const token = localStorage.getItem('token');
        
        if (!CertificateBase64 || !RNC || !CertificatePass) {
            alert("Por favor, completa todos los campos.");
            return;
        }

        // Prepara el objeto JSON
        const json = {
            rnc: RNC,
            CertificateBase64: CertificateBase64,
            certificatePass: CertificatePass
        };

        // Imprime el JSON en la consola
        console.log('Datos del certificado:', JSON.stringify(json, null, 2));

        try {
            const response = await fetch('https://logiconefeuat.azurewebsites.net/in/Certificatesave/api', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(json)
            });

            const result = await response.json();
            
            if (!response.ok) { // Manejo de error basado en el estado de la respuesta
                throw new Error(result.Message || 'Error al guardar el certificado.');
            }

            setModalOpen(false);
            setLoading(true);
            const newDataResponse = await fetch('https://logiconefeuat.azurewebsites.net/in/Certificate/api', {
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            });
            const newData = await newDataResponse.json();
            setData(newData.Data || []);
            setFilteredData(newData.Data || []);
        } catch (err) {
            console.error(err);
            alert(err.message);
        } finally {
            setLoading(false);
        }
    };

    if (loading) return <div>Cargando...</div>;
    if (error) return <div>{error}</div>;

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '15px' }}>
                <Button.Group>
                    <Button icon labelPosition='left' color='grey' onClick={handleNew}>
                        <Icon name='plus' /> Nuevo
                    </Button>
                    <Button icon labelPosition='left' color='grey' onClick={handleDelete} disabled={!selectedRow}>
                        <Icon name='trash' /> Eliminar
                    </Button>
                </Button.Group>
                <Input
                    icon='search'
                    placeholder='Buscar...'
                    value={searchTerm}
                    onChange={handleSearch}
                    style={{ width: '250px' }}
                />
            </div>

            <Table celled selectable>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>ID</Table.HeaderCell>
                        <Table.HeaderCell>RNC</Table.HeaderCell>
                        <Table.HeaderCell>Emitido Por</Table.HeaderCell>
                        <Table.HeaderCell>Número de Certificado</Table.HeaderCell>
                        <Table.HeaderCell>Fecha de Emisión</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {currentItems.map(item => (
                        <Table.Row key={item.Id} onClick={() => setSelectedRow(item.Id)}>
                            <Table.Cell>{item.Id}</Table.Cell>
                            <Table.Cell>{item.RNC}</Table.Cell>
                            <Table.Cell>{item.IssuedBy}</Table.Cell>
                            <Table.Cell>{item.CertificateSerialNumber}</Table.Cell>
                            <Table.Cell>{item.EmissionDate}</Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>

            {/* Paginador */}
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                <Pagination
                    activePage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handleClickPage}
                    boundaryRange={1} // Cambiado de 0 a 1
                    siblingRange={1}
                    firstItem={{ content: <Icon name='angle double left' />, icon: true }}
                    lastItem={{ content: <Icon name='angle double right' />, icon: true }}
                    prevItem={{ content: <Icon name='angle left' />, icon: true }}
                    nextItem={{ content: <Icon name='angle right' />, icon: true }}
                />
            </div>

            <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
                <Modal.Header>Nuevo Certificado</Modal.Header>
                <Modal.Content style={{ width: '50%' }}>
                    <Form>
                        <Form.Input
                            label='RNC'
                            name='RNC'
                            value={formData.RNC}
                            onChange={handleChange}
                        />
                        <Form.Input
                            label='Certificado (.cer o .pfx)'
                            type='file'
                            accept=".cer,.pfx"
                            onChange={handleFileChange}
                        />
                        <Form.Input
                            label='Contraseña'
                            name='CertificatePass'
                            type='password'
                            value={formData.CertificatePass}
                            onChange={handleChange}
                        />
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                     <Button primary onClick={handleSubmit}>Guardar</Button>
                    <Button onClick={() => setModalOpen(false)}>Cancelar</Button>
                </Modal.Actions>
            </Modal>
        </div>
    );
};

export default CertificadosGrid; 