import React, { useState } from 'react';
import { Menu, Icon, Loader, Message } from 'semantic-ui-react';
import './Dashboard.css';
import VoucherTypeGrid from '../components/VoucherTypeGrid';
import CertificadosGrid from '../components/CertificadosGrid'; 
import XSDGrid from '../components/XSDGrid'; // Asegúrate de tener el componente XSDGrid
import UrlGrid from '../components/UrlGrid'; // Importa el nuevo componente

const Dashboard = ({ user, onLogout }) => {
    const [showDropdown, setShowDropdown] = useState(false);
    const [showSubmenu, setShowSubmenu] = useState(false);
    const [showConfigSubmenu, setShowConfigSubmenu] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [showVoucherTypeGrid, setShowVoucherTypeGrid] = useState(false);
    const [showCertificadosGrid, setShowCertificadosGrid] = useState(false);
    const [showXSDGrid, setShowXSDGrid] = useState(false); // Estado para mostrar XSDGrid
    const [showUrlGrid, setShowUrlGrid] = useState(false); // Estado para mostrar UrlGrid

    const handleLogout = () => {
        onLogout();
        setShowDropdown(false);
    };

    const toggleSubmenu = () => {
        setShowSubmenu(!showSubmenu);
        if (showSubmenu) {
            setShowConfigSubmenu(false);
            setShowVoucherTypeGrid(false);
            setShowCertificadosGrid(false);
            setShowXSDGrid(false); // Resetea XSDGrid
            setShowUrlGrid(false); // Resetea UrlGrid
        }
    };

    const toggleConfigSubmenu = () => {
        setShowConfigSubmenu(!showConfigSubmenu);
        if (showConfigSubmenu) {
            setShowVoucherTypeGrid(false);
            setShowCertificadosGrid(false);
            setShowXSDGrid(false); // Resetea XSDGrid
            setShowUrlGrid(false); // Resetea UrlGrid
        }
    };

    const handleTipoComprobanteClick = () => {
        setLoading(true);
        setShowVoucherTypeGrid(true);
        setShowCertificadosGrid(false);
        setShowXSDGrid(false); // Oculta XSDGrid
        setShowUrlGrid(false); // Oculta UrlGrid
        setError(null);

        setTimeout(() => {
            setLoading(false);
        }, 1000);
    };

    const handleCertificadosClick = () => {
        setLoading(true);
        setShowCertificadosGrid(true);
        setShowVoucherTypeGrid(false);
        setShowXSDGrid(false); // Oculta XSDGrid
        setShowUrlGrid(false); // Oculta UrlGrid
        setError(null);

        setTimeout(() => {
            setLoading(false);
        }, 1000);
    };

    const handleXSDClick = () => {
        setLoading(true);
        setShowXSDGrid(true);
        setShowCertificadosGrid(false);
        setShowVoucherTypeGrid(false); // Oculta los demás grids
        setShowUrlGrid(false); // Oculta UrlGrid
        setError(null);

        setTimeout(() => {
            setLoading(false);
        }, 1000);
    };

    const handleUrlsClick = () => {
        setLoading(true);
        setShowUrlGrid(true); // Muestra UrlGrid
        setShowVoucherTypeGrid(false);
        setShowCertificadosGrid(false);
        setShowXSDGrid(false); // Oculta XSDGrid
        setError(null);

        setTimeout(() => {
            setLoading(false);
        }, 1000);
    };

    return (
        <div className="dashboard">
            <div className="top-panel">
                <h1 style={{ margin: 0, fontSize: '1.5vw' }}>Darios Computer P&P</h1>
                <div className="avatar" onClick={() => setShowDropdown(!showDropdown)} style={{ position: 'relative' }}>
                    <span style={{ fontSize: '1.2em' }}>{user.UserName}</span> {/* Mostrando el nombre del usuario */}
                    {showDropdown && (
                        <div className="dropdown">
                            <button className="logout-button" onClick={handleLogout}>Logout</button>
                        </div>
                    )}
                </div>
            </div>
            <div style={{ display: 'flex', flex: '1' }}>
                <Menu vertical className="left-panel" style={{ width: '260px', backgroundColor: '#f7f7f7' }}>
                    <Menu.Item style={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}>
                        <Icon name='home' style={{ marginRight: '10px' }} />
                        Inicio
                    </Menu.Item>
                    <Menu.Item style={{ marginTop: '10px' }} onClick={toggleSubmenu}>
                        <h4 style={{ display: 'flex', alignItems: 'center', margin: 0 }}>
                            <Icon name='globe' style={{ marginRight: '10px' }} /> {/* Icono de Proveedor */}
                            Proveedor de Servicios FE
                            <Icon name={showSubmenu ? 'angle up' : 'angle down'} style={{ marginLeft: 'auto' }} />
                        </h4>
                    </Menu.Item>
                    {showSubmenu && (
                        <>
                            <Menu.Item style={{ marginLeft: '20px', marginTop: '10px', display: 'flex', alignItems: 'center' }} onClick={toggleConfigSubmenu}>
                                <Icon name='cogs' style={{ marginRight: '10px' }} />
                                Configuración Básica
                                <Icon name={showConfigSubmenu ? 'angle up' : 'angle down'} style={{ marginLeft: 'auto' }} />
                            </Menu.Item>
                            {showConfigSubmenu && (
                                <>
                                    <Menu.Item 
                                        style={{ marginLeft: '40px', marginTop: '10px', display: 'flex', alignItems: 'center' }} 
                                        onClick={handleTipoComprobanteClick}
                                    >
                                        <Icon name='file alternate' style={{ marginRight: '10px' }} />
                                        Tipos de Comprobante
                                    </Menu.Item>
                                    <Menu.Item 
                                        style={{ marginLeft: '40px', marginTop: '10px', display: 'flex', alignItems: 'center' }} 
                                        onClick={handleCertificadosClick}
                                    >
                                        <Icon name='certificate' style={{ marginRight: '10px' }} />
                                        Certificados
                                    </Menu.Item>
                                    <Menu.Item 
                                        style={{ marginLeft: '40px', marginTop: '10px', display: 'flex', alignItems: 'center' }} 
                                        onClick={handleXSDClick}
                                    >
                                        <Icon name='file code' style={{ marginRight: '10px' }} />
                                        XSD
                                    </Menu.Item>
                                    <Menu.Item 
                                        style={{ marginLeft: '40px', marginTop: '10px', display: 'flex', alignItems: 'center' }} 
                                        onClick={handleUrlsClick} // Añade el manejador para URLs
                                    >
                                        <Icon name='linkify' style={{ marginRight: '10px' }} /> {/* Icono de URL */}
                                        URLs
                                    </Menu.Item>
                                    {loading && <Loader active inline='centered' />}
                                    {error && <Message negative>{error}</Message>}
                                </>
                            )}
                            <Menu.Item style={{ marginLeft: '20px', marginTop: '10px', display: 'flex', alignItems: 'center' }}>
                                <Icon name='exchange' style={{ marginRight: '10px' }} />
                                Transacciones
                            </Menu.Item>
                            <Menu.Item style={{ marginLeft: '20px', marginTop: '10px', display: 'flex', alignItems: 'center' }}>
                                <Icon name='clipboard check' style={{ marginRight: '10px' }} />
                                Clientes y Suscripciones
                            </Menu.Item>
                        </>
                    )}
                </Menu>
                <div style={{ flex: '1', display: 'flex', flexDirection: 'column', marginTop: 0 }}>
                    <div className="content" style={{ flex: '1', padding: '20px' }}>
                        {/* Contenido principal del Dashboard */}
                        {showVoucherTypeGrid && <VoucherTypeGrid />}
                        {showCertificadosGrid && <CertificadosGrid />}
                        {showXSDGrid && <XSDGrid />} {/* Renderiza XSDGrid */}
                        {showUrlGrid && <UrlGrid />} {/* Renderiza UrlGrid */}
                    </div>
                </div>
            </div>
            <footer style={{ height: '5%', backgroundColor: 'lightgray', textAlign: 'center', padding: '10px' }}>
                <p>Copyright &copy; {new Date().getFullYear()} Darios Computer P&P. Todos los derechos reservados.</p>
            </footer>
        </div>
    );
};

export default Dashboard;
