import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; 
import Login from './authentication/Login';
import Dashboard from './dashboard/Dashboard';
import 'semantic-ui-css/semantic.min.css';

const App = () => {
    const [user, setUser] = useState(null);

    // Recuperar usuario del localStorage al cargar la aplicación
    useEffect(() => {
        const storedUser = localStorage.getItem('user');
        if (storedUser) {
            setUser(JSON.parse(storedUser)); // Parsear el JSON a objeto
        }
    }, []);

    const handleLogin = (userData) => {
        console.log('Usuario logueado:', userData);
        setUser(userData);
        localStorage.setItem('user', JSON.stringify(userData)); // Guardar en localStorage
    };

    const handleLogout = () => {
        setUser(null);
        localStorage.removeItem('user'); // Eliminar del localStorage al cerrar sesión
    };

    return (
        <Router>
            <Routes>
                <Route path="/" element={user ? <Dashboard user={user} onLogout={handleLogout} /> : <Login onLogin={handleLogin} />} />
                {/* Puedes agregar más rutas aquí según sea necesario */}
            </Routes>
        </Router>
    );
};

export default App;

