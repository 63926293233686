import React, { useState } from 'react';
import './Login.css'; // Asegúrate de que la ruta sea correcta

const Login = ({ onLogin }) => {
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null); // Para manejar errores de autenticación

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        const userData = {
            userName,
            password,
            portal: "1" // Asumimos que "portal" es constante y siempre es 1
        };

        try {
            const response = await fetch('https://logiconefeuat.azurewebsites.net/in/user/login/api', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(userData),
            });

            const data = await response.json();

            if (data.WithError) {
                setError(data.Message); // Mostrar mensaje de error
                return;
            }

            const { Token, ...userInfo } = data.Data; // Extraemos el token y el resto de la información del usuario
            localStorage.setItem('token', Token); // Almacenar el token en localStorage
            onLogin(userInfo); // Pasar el resto de la información del usuario a onLogin
        } catch (err) {
            setError('Error al autenticar. Intente de nuevo.'); // Manejo de errores de red
        }
    };

    return (
        <div className="login-container">
            <div className="login-popup">
                <h2>Iniciar Sesión</h2>
                <form onSubmit={handleSubmit}>
                    <label htmlFor="username">Usuario:</label>
                    <input
                        type="text"
                        id="username"
                        value={userName}
                        onChange={(e) => setUserName(e.target.value)}
                        required
                    />
                    <label htmlFor="password">Contraseña:</label>
                    <input
                        type="password"
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                    <button type="submit">Entrar</button>
                </form>
                {error && <div className="error-message">{error}</div>} {/* Mostrar errores */}
            </div>
        </div>
    );
};

export default Login;
