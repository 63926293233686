import React, { useEffect, useState } from 'react';
import { Table, Button, Icon, Input, Pagination, Modal, Form } from 'semantic-ui-react';

const UrlGrid = () => {
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(7);
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Estados para el popup de agregar/editar
    const [modalOpen, setModalOpen] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [formData, setFormData] = useState({
        URLS: '',
        DESCRIPTION: '',
        NCFURLTYPE: 0,
        Ambiente: 0,
        Id: 0,
    });

    // Llamada a la API
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const token = localStorage.getItem('token');
                const response = await fetch('https://logiconefeuat.azurewebsites.net/in/url/api', {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    }
                });
                const result = await response.json();
                if (!result.WithError) {
                    setData(result.Data || []);
                    setFilteredData(result.Data || []);
                } else {
                    setError(result.Message || 'Error al cargar las URLs.');
                }
            } catch (err) {
                setError('Error al cargar las URLs.');
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    // Manejo de la búsqueda
    const handleSearch = (e) => {
        const term = e.target.value.toLowerCase();
        setSearchTerm(term);
        const filtered = data.filter(item => 
            item.URLS.toLowerCase().includes(term) || 
            item.DESCRIPTION.toLowerCase().includes(term)
        );
        setFilteredData(filtered);
        setCurrentPage(1);
    };

    // Manejo de la paginación
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(filteredData.length / itemsPerPage);

    const handleClickPage = (e, { activePage }) => {
        setCurrentPage(activePage);
    };

    // Abrir modal de nuevo registro
    const handleNew = () => {
        setIsEditMode(false);
        setFormData({
            URLS: '',
            DESCRIPTION: '',
            NCFURLTYPE: 0,
            Ambiente: 0,
            Id: 0,
        });
        setModalOpen(true);
    };

    // Abrir modal para editar registro
    const handleEdit = () => {
        if (selectedRow) {
            const itemToEdit = data.find(item => item.Id === selectedRow);
            setFormData(itemToEdit);
            setIsEditMode(true);
            setModalOpen(true);
        }
    };

    // Manejo del cambio en los campos del formulario
    const handleChange = (e, { name, value }) => {
        setFormData({
            ...formData,
            [name]: value
        });
    };

    // Manejo de la eliminación de un registro
    const handleDelete = async () => {
        if (!selectedRow) return;

        const confirmed = window.confirm("¿Estás seguro de que quieres eliminar esta URL?");
        if (!confirmed) return;

        const token = localStorage.getItem('token');
        try {
            const response = await fetch(`https://logiconefeuat.azurewebsites.net/in/url/api/${selectedRow}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            const result = await response.json();
            if (!result.WithError) {
                const newDataResponse = await fetch('https://logiconefeuat.azurewebsites.net/in/url/api', {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    }
                });
                const newData = await newDataResponse.json();
                setData(newData.Data || []);
                setFilteredData(newData.Data || []);
                setSelectedRow(null); // Resetea la fila seleccionada
            } else {
                alert(result.Message || 'Error al eliminar la URL.');
            }
        } catch (err) {
            alert('Error al eliminar la URL.');
        }
    };

    // Envío de datos al endpoint
    const handleSubmit = async () => {
        const token = localStorage.getItem('token');
        try {
            const method = isEditMode ? 'PUT' : 'POST'; // Usa PUT para editar, POST para crear
            const response = await fetch('https://logiconefeuat.azurewebsites.net/in/url/api', {
                method,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });
            const result = await response.json();
            if (!result.WithError) {
                setModalOpen(false);
                const newDataResponse = await fetch('https://logiconefeuat.azurewebsites.net/in/url/api', {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    }
                });
                const newData = await newDataResponse.json();
                setData(newData.Data || []);
                setFilteredData(newData.Data || []);
            } else {
                alert(result.Message || 'Error al guardar la URL.');
            }
        } catch (err) {
            alert('Error al guardar la URL.');
        }
    };

    if (loading) return <div>Cargando...</div>;
    if (error) return <div>{error}</div>;

    return (
        <div>
            {/* Botón group con el buscador */}
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '15px' }}>
                <Button.Group>
                    <Button icon labelPosition='left' color='grey' onClick={handleNew}>
                        <Icon name='plus' /> Nuevo
                    </Button>
                    <Button icon labelPosition='left' color='grey' onClick={handleEdit} disabled={!selectedRow}>
                        <Icon name='edit' /> Editar
                    </Button>
                    <Button icon labelPosition='left' color='grey' onClick={handleDelete} disabled={!selectedRow}>
                        <Icon name='trash' /> Eliminar
                    </Button>
                </Button.Group>

                {/* Buscador */}
                <Input
                    icon='search'
                    placeholder='Buscar...'
                    value={searchTerm}
                    onChange={handleSearch}
                    style={{ width: '250px' }}
                />
            </div>

            {/* Tabla con selección */}
            <Table celled selectable>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>ID</Table.HeaderCell>
                        <Table.HeaderCell>URL</Table.HeaderCell>
                        <Table.HeaderCell>Descripción</Table.HeaderCell>
                        <Table.HeaderCell>Tipo de NCF</Table.HeaderCell>
                        <Table.HeaderCell>Ambiente</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {currentItems.map((item) => (
                        <Table.Row 
                            key={item.Id}
                            active={selectedRow === item.Id}
                            onClick={() => setSelectedRow(item.Id)}
                        >
                            <Table.Cell>{item.Id}</Table.Cell>
                            <Table.Cell>{item.URLS}</Table.Cell>
                            <Table.Cell>{item.DESCRIPTION}</Table.Cell>
                            <Table.Cell>{item.NCFURLTYPE}</Table.Cell>
                            <Table.Cell>{item.Ambiente}</Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>

          {/* Paginador */}
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                <Pagination
                    activePage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handleClickPage}
                    boundaryRange={1} // Cambiado de 0 a 1
                    siblingRange={1}
                    firstItem={{ content: <Icon name='angle double left' />, icon: true }}
                    lastItem={{ content: <Icon name='angle double right' />, icon: true }}
                    prevItem={{ content: <Icon name='angle left' />, icon: true }}
                    nextItem={{ content: <Icon name='angle right' />, icon: true }}
                />
            </div>


            {/* Modal para agregar/editar */}
            <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
                <Modal.Header>{isEditMode ? 'Editar URL' : 'Agregar URL'}</Modal.Header>
                <Modal.Content>
                    <Form>
                        <Form.Input
                            label='URL'
                            name='URLS'
                            value={formData.URLS}
                            onChange={handleChange}
                        />
                        <Form.Input
                            label='Descripción'
                            name='DESCRIPTION'
                            value={formData.DESCRIPTION}
                            onChange={handleChange}
                        />
                        <Form.Input
                            label='Tipo de NCF'
                            name='NCFURLTYPE'
                            type='number'
                            value={formData.NCFURLTYPE}
                            onChange={handleChange}
                        />
                        <Form.Input
                            label='Ambiente'
                            name='Ambiente'
                            type='number'
                            value={formData.Ambiente}
                            onChange={handleChange}
                        />
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button color='black' onClick={() => setModalOpen(false)}>
                        Cancelar
                    </Button>
                    <Button positive onClick={handleSubmit}>
                        Guardar
                    </Button>
                </Modal.Actions>
            </Modal>
        </div>
    );
};

export default UrlGrid;
