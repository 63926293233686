import React, { useEffect, useState } from 'react';
import { Table, Button, Icon, Input, Pagination, Modal, Form, Dropdown } from 'semantic-ui-react';

const VoucherTypeGrid = () => {
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Estados para el popup de agregar/editar
    const [modalOpen, setModalOpen] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [formData, setFormData] = useState({
        NCFVoucherTypeId: '',
        NCFVoucherTypeDesc: '',
        ModuleOperator: '1',
        DocumentType: '1',
        InternalNTR: false,
        IsElectronicReceipt: false,
        IsSummary: false,
        Id: 0,
    });

    // Opciones para el ComboBox ModuleOperator
    const moduleOperatorOptions = [
        { key: '1', text: 'Ventas', value: '1' },
        { key: '2', text: 'Compras', value: '2' },
        { key: '3', text: 'Bancos', value: '3' },
        { key: '4', text: 'Compra-Banco', value: '4' },
        { key: '5', text: 'Todos', value: 'All' },
    ];

    // Opciones para el ComboBox DocumentType
    const documentTypeOptions = [
        { key: '1', text: 'Factura', value: '1' },
        { key: '2', text: 'Nota de débito', value: '2' },
        { key: '3', text: 'Nota de crédito', value: '3' },
        { key: '4', text: 'No configurada', value: '4' },
    ];

    // Llamada a la API
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const token = localStorage.getItem('token');
                const response = await fetch('https://logiconefeuat.azurewebsites.net/in/vouchertype/api', {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    }
                });
                const result = await response.json();
                if (!result.WithError) {
                    setData(result.Data || []);
                    setFilteredData(result.Data || []);
                } else {
                    setError(result.Message || 'Error al cargar los tipos de comprobante.');
                }
            } catch (err) {
                setError('Error al cargar los tipos de comprobante.');
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    // Manejo de la búsqueda
    const handleSearch = (e) => {
        const term = e.target.value.toLowerCase();
        setSearchTerm(term);
        const filtered = data.filter(item => 
            item.NCFVoucherTypeDesc.toLowerCase().includes(term) || 
            item.NCFVoucherTypeId.toString().includes(term)
        );
        setFilteredData(filtered);
        setCurrentPage(1);
    };

    // Manejo de la paginación
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(filteredData.length / itemsPerPage);

    const handleClickPage = (e, { activePage }) => {
        setCurrentPage(activePage);
    };

    // Abrir modal de nuevo registro
    const handleNew = () => {
        setIsEditMode(false);
        setFormData({
            NCFVoucherTypeId: '',
            NCFVoucherTypeDesc: '',
            ModuleOperator: '1',
            DocumentType: '1',
            InternalNTR: false,
            IsElectronicReceipt: false,
            IsSummary: false,
            Id: 0
        });
        setModalOpen(true);
    };

    // Abrir modal para editar registro
    const handleEdit = () => {
        if (selectedRow) {
            const itemToEdit = data.find(item => item.Id === selectedRow);
            setFormData(itemToEdit);
            setIsEditMode(true);
            setModalOpen(true);
        }
    };

    // Manejo del cambio en los campos del formulario
    const handleChange = (e, { name, value }) => {
        setFormData({
            ...formData,
            [name]: value
        });
    };
// Manejo de la eliminación de un registro
const handleDelete = async () => {
    if (!selectedRow) return;

    const confirmed = window.confirm("¿Estás seguro de que quieres eliminar este comprobante?");
    if (!confirmed) return;

    const token = localStorage.getItem('token');
    try {
        const response = await fetch(`https://logiconefeuat.azurewebsites.net/in/vouchertype/api/${selectedRow}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        });
        const result = await response.json();
        if (!result.WithError) {
            // Actualiza los datos después de eliminar
            setLoading(true);
            const newDataResponse = await fetch('https://logiconefeuat.azurewebsites.net/in/vouchertype/api', {
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            });
            const newData = await newDataResponse.json();
            setData(newData.Data || []);
            setFilteredData(newData.Data || []);
            setLoading(false);
            setSelectedRow(null); // Resetea la fila seleccionada
        } else {
            alert(result.Message || 'Error al eliminar el comprobante.');
        }
    } catch (err) {
        alert('Error al eliminar el comprobante.');
    }
};


    // Envío de datos al endpoint
    const handleSubmit = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await fetch('https://logiconefeuat.azurewebsites.net/in/vouchertype/api', {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });
            const result = await response.json();
            if (!result.WithError) {
                // Recargar los datos después de guardar
                setModalOpen(false);
                setLoading(true);
                const newDataResponse = await fetch('https://logiconefeuat.azurewebsites.net/in/vouchertype/api', {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    }
                });
                const newData = await newDataResponse.json();
                setData(newData.Data || []);
                setFilteredData(newData.Data || []);
                setLoading(false);
            } else {
                alert(result.Message || 'Error al guardar el comprobante.');
            }
        } catch (err) {
            alert('Error al guardar el comprobante.');
        }
    };

    if (loading) return <div>Cargando...</div>;
    if (error) return <div>{error}</div>;

    return (
        <div>
            {/* Botón group con el buscador */}
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '15px' }}>
                <Button.Group>
                    <Button icon labelPosition='left' color='grey' onClick={handleNew}>
                        <Icon name='plus' /> Nuevo
                    </Button>
                    <Button icon labelPosition='left' color='grey' onClick={handleEdit} disabled={!selectedRow}>
                        <Icon name='edit' /> Editar
                    </Button>
                    <Button icon labelPosition='left' color='grey' onClick={handleDelete} disabled={!selectedRow}>
                     <Icon name='trash' /> Eliminar
                    </Button>

                   
                </Button.Group>

                {/* Buscador */}
                <Input
                    icon='search'
                    placeholder='Buscar...'
                    value={searchTerm}
                    onChange={handleSearch}
                    style={{ width: '250px' }}
                />
            </div>

            {/* Tabla con selección */}
            <Table celled selectable>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Tipo</Table.HeaderCell>
                        <Table.HeaderCell>Descripción</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {currentItems.map((item) => (
                        <Table.Row 
                            key={item.Id}
                            active={selectedRow === item.Id}
                            onClick={() => setSelectedRow(item.Id)}
                        >
                            <Table.Cell>{item.NCFVoucherTypeId}</Table.Cell>
                            <Table.Cell>{item.NCFVoucherTypeDesc}</Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>

            {/* Paginador */}
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                <Pagination
                    activePage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handleClickPage}
                    boundaryRange={1} // Cambiado de 0 a 1
                    siblingRange={1}
                    firstItem={{ content: <Icon name='angle double left' />, icon: true }}
                    lastItem={{ content: <Icon name='angle double right' />, icon: true }}
                    prevItem={{ content: <Icon name='angle left' />, icon: true }}
                    nextItem={{ content: <Icon name='angle right' />, icon: true }}
                />
            </div>

            {/* Modal para agregar o editar */}
            <Modal
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                size='small'
                style={{ maxWidth: '600px', margin: '0 auto' }} // Ancho máximo y centrado
            >
                <Modal.Header>{isEditMode ? 'Editar Comprobante' : 'Nuevo Comprobante'}</Modal.Header>
                <Modal.Content style={{ width: '50%' }}> {/* Establecer el ancho al 50% */}
                    <Form>
                        <Form.Input
                            label='ID'
                            name='NCFVoucherTypeId'
                            value={formData.NCFVoucherTypeId}
                            onChange={handleChange}
                        />
                        <Form.Input
                            label='Descripción'
                            name='NCFVoucherTypeDesc'
                            value={formData.NCFVoucherTypeDesc}
                            onChange={handleChange}
                        />
                        <Form.Field
                            control={Dropdown}
                            label='Módulo'
                            name='ModuleOperator'
                            options={moduleOperatorOptions}
                            value={formData.ModuleOperator}
                            onChange={handleChange}
                            selection
                        />
                        <Form.Field
                            control={Dropdown}
                            label='Tipo de Documento'
                            name='DocumentType'
                            options={documentTypeOptions}
                            value={formData.DocumentType}
                            onChange={handleChange}
                            selection
                        />
                        <Form.Checkbox
                            label='NTR Interno'
                            name='InternalNTR'
                            checked={formData.InternalNTR}
                            onChange={(e, { checked }) => setFormData({ ...formData, InternalNTR: checked })}
                        />
                        <Form.Checkbox
                            label='Es Recibo Electrónico'
                            name='IsElectronicReceipt'
                            checked={formData.IsElectronicReceipt}
                            onChange={(e, { checked }) => setFormData({ ...formData, IsElectronicReceipt: checked })}
                        />
                        <Form.Checkbox
                            label='Es Resumen'
                            name='IsSummary'
                            checked={formData.IsSummary}
                            onChange={(e, { checked }) => setFormData({ ...formData, IsSummary: checked })}
                        />
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button primary onClick={handleSubmit}>
                        Guardar
                    </Button>
                    <Button onClick={() => setModalOpen(false)}>
                        Cancelar
                    </Button>
                </Modal.Actions>
            </Modal>
        </div>
    );
};

export default VoucherTypeGrid;
